<!--<div class="container">
  <h3 mat-dialog-title class="mt-0">
    {{modalTitle}}
    <button mat-icon-button mat-dialog-close>
      <mat-icon matTooltip="Close">close</mat-icon>
    </button>
  </h3>

  <form mat-dialog-content id="selectPersonRoleForm" #selectPersonRoleForm="ngForm">
    <div class="term-of-use">
      <p>Last modified: June 11, 2020</p>
      <p class="bold copyright">© COPYRIGHT <span class="year">{{currentYear}}</span> THRIVEPASS Online ALL RIGHTS RESERVED.</p>
      <p>
        YOUR ACCEPTANCE OF THE TERMS OF USE. Welcome to THRIVEPASS! The website thrivepass.com (the “Site”) is
        brought to you by
        ThrivePass, Inc. (“THRIVEPASS,” “we,” “us,” or “our”). This user agreement sets forth the “terms
        and
        conditions” which govern your use of the Site and of the services and features made available on or through
        the Site (as
        further described in Section 2.A below and including, but not limited to the “Registered User Services,” the
        “Services”).
      </p>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE (the “Terms of Use,” or the “Agreement”) CAREFULLY BEFORE USING
        THIS SITE
        AS THEY CONSTITUTE A LEGALLY BINDING CONTRACT BETWEEN YOU AND THRIVEPASS AND GOVERN YOUR ACCESS TO THE SITE.
        BY
        ACCESSING OR USING THIS SITE AND/OR THE SERVICES IN ANY MANNER, YOU INDICATE YOUR UNCONDITIONAL ACCEPTANCE
        OF (1) THESE
        TERMS OF USE, (2) OUR PRIVACY POLICY (“THE PRIVACY POLICY”); AND (3) ANY OTHER LEGAL NOTICES, CONDITIONS, OR
        GUIDELINES
        LOCATED ON THE SITE. IF YOU DO NOT AGREE TO THESE TERMS OF USE OR THE PRIVACY POLICY, PLEASE DO NOT USE THE
        SITE OR
        SERVICES AND EXIT NOW. WE RESERVE THE RIGHT, IN OUR SOLE DISCRETION, TO UPDATE OR REVISE THESE TERMS OF USE
        FROM TIME TO
        TIME. YOUR CONTINUED USE OF THE SITE AND/OR SERVICES NOW, OR FOLLOWING THE POSTING OR NOTICE OF ANY CHANGES
        TO THE TERMS
        OF USE OR PRIVACY POLICY WILL INDICATE YOUR ACCEPTANCE OF THOSE CHANGES.
      </p>
      <ol>
        <li>
          SITE USERS. Two types of users may access the Site and use the Services – "Visitors" and "Registered
          Users."
        </li>

          <li>
            Visitors: To become a visitor of the Site (“Visitor”), you must read, agree with, and consent to be
            bound by all the
            terms and conditions set forth in these Terms of Use and the Privacy Policy As a Visitor, You may
            access the Site, and
            read our general information. You may NOT access any password-restricted information and/or portions
            of the Site, or
            upload any information.
          </li>
          <li>
            Registered Users: To become a registered user of the Site (“Registered User”) and to access the
            password-restricted
            areas of the Site and the Registered User Services (as defined in Section 2.A below), You must read,
            agree with, and
            consent to be bound by all the terms and conditions set forth in these Terms of Use, and the Privacy
            Policy. You may
            become a Registered User of the Site, by accessing the unique link provided to you upon initiation,
            for the Site.
            Without limiting the foregoing, the password-restricted areas of the Site and the Registered User
            Services are not
            available to temporarily or indefinitely suspended Registered Users or to persons under 18 years of
            age.
            <br><br>You are responsible for maintaining the confidentiality and security of your account and
            password, and you are fully
            responsible for all activities that occur under your password or account, and for any other actions
            taken in connection
            with the account or password. You agree to (a) immediately notify THRIVEPASS of any known or
            suspected unauthorized
            use(s) of your password or account, or any known or suspected breach of security, including loss,
            theft, or unauthorized
            disclosure of your password or credit card information; and (b) ensure that you exit from your
            account at the end of
            each session. THRIVEPASS will not be liable for any injury, loss or damage of any kind arising from
            or relating to your
            failure to comply with (a) and (b) or for any acts or omissions by you or someone else using your
            account and/or
            password.
          </li>
        </ol>
        <li>
          USE OF CONTENT AND SERVICES AVAILABLE THROUGH THE SITE.
          <p>
            Content. The Site contains a wide variety
            of Content (defined below), whether (1) proprietary to THRIVEPASS, or (2)
            proprietary to third parties, or Content proprietary to our commercial partners. “Content” includes,
            but
            is not limited
            to text, software, scripts, data, graphics, photos, sounds, music, videos, audiovisual combinations,
            interactive
            features and other materials that you may view or access through the Site, and Registered User
            Content.
            Under no
            circumstances will THRIVEPASS be liable in any way for any Content, including any errors or
            omissions in
            any Content or
            any Content that is libelous or defamatory, or any loss or damage of any kind incurred as a result
            of
            your use of any
            Content. You agree that you must evaluate and bear all risks associated with the use of any Content,
            including any
            reliance on the Content, integrity, and accuracy of such Content.
          </p>
        </li>
        <ol>
          <li>
            Services. Through the Site, we offer (1) to Visitors and to Registered Users free of cost access to
            our
            description of
            the service and (2) to Registered Users only, access to an online system that allows users to
            purchase
            wellness products
            and other employee benefit administration products, both one-time and recurring.
          </li>
          <li>Restrictions on Your Use of Content. The following restrictions apply to your use of Content:</li>
          <ol>
            <li>
              The Content and the trademarks, services marks and logos on the Site are owned by or licensed to
              THRIVEPASS, subject to
              copyright and other intellectual property rights under the law.
            </li>
            <li>
              Content is provided to You AS IS. You may access Content for your information and use solely as
              intended through the
              provided functionality of the Site and as permitted under this Agreement. You shall not use
              Content in any manner that
              infringes our or any third party's intellectual property rights, or other proprietary right or
              rights of publicity in or
              to the Content. All rights not expressly granted herein by THRIVEPASS and/or its licensors to
              you are reserved by
              THRIVEPASS and/or its licensors.
            </li>
            <li>
              You agree not to circumvent, disable or otherwise interfere with security-related features of
              the Site and/or Services
              that prevent or restrict the use or copying of any Content and not to alter, remove, or falsify
              any attributions or
              other proprietary designations of origin or source of any Content appearing on the Site or
              contained in a file that is
              uploaded to the Site.
            </li>
          </ol>
          <li>
            Restrictions on Your Rights to Use the Site/Materials. You agree that you shall not (and you agree
            not to allow any
            third party to): (i) access or use the Site and/or the Materials (as defined in Section 3 below) in
            an unlawful way or
            for an unlawful or illegitimate purpose or in any manner that contravenes this Agreement and our
            Privacy Policy; (ii)
            attempt to disrupt the operation of the Site through use of methods such as viruses, Trojan horses,
            worms, time bombs,
            denial of service attacks, flooding or spamming; (iii) use the Site/Materials in any manner that
            could damage, disable
            or impair our services or networks; (iv) attempt, through any means, to gain unauthorized access to
            the Site, Services,
            to a Registered User’s account, and/or any computer systems or networks, through hacking, password
            mining or any other
            means; (v) impersonate any person or entity, or falsely state or otherwise misrepresent your
            affiliation with a person
            or entity; (vi) use any robot, spider, site search/retrieval application, or other device to access,
            retrieve, screen
            scrape, screen surf or employ any other data scraping method, or index any portion of the
            Site/Services/Materials for
            any purpose. Systematic retrieval of data or other content from the Site to create or compile,
            directly or indirectly, a
            collection, compilation, database or directory is prohibited; (vii) create a Registered User account
            by automated means
            or register as a Registered User under false or fraudulent pretenses; (viii) create or transmit
            unwanted electronic
            communications such as "spam" to Registered Users or otherwise interfere with Visitors and/or
            Registered Users’
            enjoyment of the Site; (ix) reformat or frame any portion of the web pages that are part of the
            Site; (x) use the Site/
            Materials to defame, abuse, harass, threaten or otherwise violate the rights of others; (xi) use the
            Site to violate the
            security of any computer network, crack passwords or security encryption codes, transfer or store
            illegal material
            including those that are deemed threatening or obscene; (xii) take any action that imposes, or may
            impose in our sole
            discretion, an unreasonable or disproportionately large load on our IT infrastructure or that may
            otherwise interfere
            with the functioning of the Site/Services; (xiii) access or otherwise use any aspect of the Site/
            Materials if you are
            not in compliance with the age requirements; (xiv) create liability for THRIVEPASS or cause
            THRIVEPASS to lose (in whole
            or in part) the services of its information technology partners or other suppliers; (xv) use the
            Site/Materials,
            intentionally or unintentionally, to violate any applicable local, state, national or international
            law; or (xvi)
            collect or store Personal Information (as defined in our Privacy Policy) about Users in connection
            with any of the
            prohibited activities described in this paragraph, (xvi) alter, or attempt to alter any register
            user’s account balance.
          </li>
        </ol>
        <li>
          PROPRIETARY RIGHTS RESTRICTIONS.
          <p>
            All material on the Site, including, but not limited to,
            Content, all informational text, design of and "look and feel,"
            layout, photographs, graphics, audio, video, messages, interactive and instant messaging, design and
            functions, files,
            documents, images, or other materials (collectively, the "Materials"), whether publicly posted or
            privately transmitted,
            as well as all derivative works thereof, are owned by THRIVEPASS or other parties that have licensed
            their material to
            THRIVEPASS, and are protected by copyright, trademark, and other intellectual property laws. All
            THRIVEPASS trademarks
            and service marks, logos, slogans and taglines are the property of THRIVEPASS. All other trademarks,
            service marks,
            logos, slogans and taglines are the property of their respective owners. Except as specifically
            permitted, nothing
            contained herein should be construed as granting any license or right to use any trademarks, service
            marks, logos,
            slogans or taglines displayed on the Site without the express written permission of THRIVEPASS, or
            such
            third-party that
            may own the trademark, service mark, logo, slogan or tagline.
          </p>
        </li>
        <ol>
          <li>
            Except as otherwise specifically permitted herein, the Materials on the Site may not be copied,
            reproduced, republished,
            uploaded, posted, transmitted, or distributed in any way, including by e-mail or other electronic
            means, without the
            prior consent of THRIVEPASS or such third-party that owns the Materials. Any modification of the
            Materials, use of the
            Materials on any web site or networked computer environment, or use of the Materials for any purpose
            other than as
            specifically permitted herein or in the applicable Registered User agreement without the prior
            consent of THRIVEPASS or
            its licensors, as applicable, is a violation of the copyright, trademark, and other proprietary
            rights in the Materials
            and is expressly prohibited.
          </li>
        </ol>
        <li>
          CHARGES FOR SERVICES.
          <p>
            You understand and agree that you are responsible for all charges relating
            to your use of THRIVEPASS Registered User
            Services that are not covered by your employer. You will be informed of the fees to be charged when
            you
            select a
            wellness product. Fees may vary from provider to provider. Providers and vendors of wellness
            products
            may increase their
            prices or pass through additional costs and you will be responsible for such costs and fees. You
            should
            monitor your
            account regularly to review and confirm any price increase or additional charges or fees. You will
            be
            responsible for
            all charges or fees related to any wellness product you select that are not covered by your
            employer. In
            the event you
            dispute any fee or charge, you must dispute such charge directly with the provider. You are free to
            cancel the services
            of any provider of wellness products through the THRIVEPASS site.
            You authorize THRIVEPASS to charge your credit card for any fees or charges that are not covered by
            your
            employer. You
            will be asked to supply credit card information, which will be verified prior to your selection of
            any
            wellness
            products. You will not be able to use the THRIVEPASS Registered User Services to purchase wellness
            products if the
            credit card information you provide is inaccurate or if your credit card is declined.
            By selecting and utilizing wellness products, you authorize us to charge your credit card for any
            charges that are not
            covered by your employer. In order to facilitate payment for your wellness products, we will share
            your
            credit card
            information and related personal information with the designated credit card payment processor. This
            information is
            shared solely for the purpose of collecting the fees.
          </p>
          <p>
            You agree to update or renew a credit card that is expired or rejected. THRIVEPASS reserves the
            right to cancel any
            wellness products and set off future employer contributions you receive from your employer to cover
            unpaid fees if you
            do not provide a valid credit card within a reasonable amount of time. THRIVEPASS may provide you
            with a
            “virtual”
            credit card which may be used solely for payment for wellness products selected on the THRIVEPASS
            site.
            In the event of
            your misuse of any virtual credit card, THRIVEPASS reserves the right to report such misuse to your
            employer and to
            terminate your further use of any virtual credit card.
            In the event you incur any overcharges or additional fees from a provider of wellness products, such
            as
            charges or fees
            caused by additional services consumed by you or penalties for missed classes, you hereby authorize
            THRIVEPASS to deduct
            these overcharges or additional fees directly from any balance remaining in your THRIVEPASS account,
            to
            set off such
            overcharges or fees from future employer contributions or to charge your personal credit card.
          </p>
          <p>
            In
            the event that you terminate any wellness products purchased through the THRIVEPASS site or are
            credited
            for any
            other charges and a provider reimburses you for any such charges, THRIVEPASS shall credit the
            balance of
            your THRIVEPASS
            account for any such reimbursement received from such provider. In the event that a reimbursement
            received by THRIVEPASS
            is linked directly to a charge to your personal credit card and you provide a written request to
            have
            such charge
            credited to your credit card, THRIVEPASS will credit such direct reimbursement to your credit card.
            You
            hereby agree and
            acknowledge that THRIVEPASS will not credit to your credit card any reimbursements or credits linked
            to
            your employer
            contributions and any such credits or reimbursements may only be utilized through the purchase of
            wellness products
            through the THRIVEPASS site. THRIVEPASS shall have no responsibility for, and shall not provide any
            reimbursements or
            credits for, any purchases made, or transactions concluded, outside of the THRIVEPASS site.
          </p>
          <p>
            If
            you separate employment from your employer for any reason, you hereby agree and consent to
            THRIVEPASS
            charging all
            fees or other charges for wellness products to your credit card.
          </p>
        </li>
        <li>
          <p>MODIFICATIONS TO THESE TERMS OF USE AND OUR SITE AND/OR SERVICES.</p>
          <p>
            You agree and understand that
            the terms of this Agreement, the Site, and/or the Services may be modified by us at any
            time in our sole discretion without prior notice, and such modifications will be effective
            immediately
            upon our posting
            of the new terms and/or upon implementation of the new changes to the Site and/or Services. You
            acknowledge that
            THRIVEPASS reserves the right to discontinue the Services, or any features, functionality or
            services
            provided as part
            of or in connection with the Site and/or the Services, and to remove Content that violates the
            requirements set forth in
            this Agreement, without prior notice. While the non-password restricted portions of the Site are
            currently free of
            charge, you acknowledge that THRIVEPASS reserves the right to charge for aspects or additions to the
            Site and/or
            Services in the future.
          </p>
          <p>
            You agree to review this Agreement periodically so that you are aware of
            the most current rights and obligations, which
            apply to your agreement with us. Your continued use of the Site and/or the Services after any
            modifications indicates
            your acceptance of the modified Agreement. The most current version of this Agreement can be
            accessed at
            any time by
            selecting the Terms of Use link on the bottom of any page on the Site. You understand that the
            Content
            is provided as a
            courtesy to our Registered Users and Visitors and that we may suspend or terminate all or a portion
            of
            the Site and/or
            Services at any time in our discretion without notice to you. Nothing in these Terms of Use shall be
            deemed to confer
            any third-party rights or benefits.
          </p>
        </li>
        <li>
          VISITOR/USER SUGGESTIONS.<p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information provided by
            you to THRIVEPASS (“Comments”) are not confidential and you hereby grant to THRIVEPASS a worldwide,
            perpetual,
            irrevocable, royalty-free license to reproduce, display, perform, distribute, publish, modify, edit
            or
            otherwise use
            such Comments as it deems appropriate, for any and all commercial or non-commercial purposes, in its
            sole discretion.
          </p>
        </li>
        <li>
          DIGITAL MILLENIUM COPYRIGHT ACT. <p>
            THRIVEPASS respects the intellectual property of others and
            expects users of our services to do the same. If you believe
            that your copyrighted work has been copied in a way that constitutes copyright infringement and is
            accessible on this
            site or through this service, you must provide the following information when providing notice of
            the
            claimed
            infringement to THRIVEPASS:
          </p>
        </li>
        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the copyright owner
            and identification of
            the copyrighted work that is infringed;
          </li>
          <li>
            Information reasonably sufficient to permit THRIVEPASS to contact you, such as an address, telephone
            number and/or
            electronic mail address;
          </li>
          <li>
            A statement that you have a good faith belief that the use of the material in the manner complained
            of is not authorized
            by the copyright owner, its agent or law;
          </li>
          <li>
            A statement that the information in the notification is accurate and under penalty of perjury, that
            the complaining
            party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
          </li>
        </ul>
        <p>
          The above information must be submitted as a written notification to THRIVEPASS by writing us at
          <a href="mailto:corp@thrivepass.com">corp@thrivepass.com</a>,
          ATTENTION: LEGAL DEPARTMENT/DMCA COMPLAINT. This information should not be construed as legal advice.
          For further
          details on the information required for valid DMCA notifications, see 17 U.S.C. 512(c)(3).
        </p>
        <li>
          LINKS TO THIRD PARTY SITES AND SERVICES; NO IMPLIED ENDORSEMENT.<p>
            This Site contains links to other web
            sites owned by third parties, which may lead to some sites maintained by our
            advertisers. Please note that when you click on any of these links, you are entering another web
            site for which
            THRIVEPASS has no responsibility or control. You agree that THRIVEPASS shall not be responsible for
            any loss or damage
            of any sort incurred as a result of any such links or as the result of the presence of such links on
            our Site. In no
            event shall any reference to any third party, third party website or third party product or service
            be construed as an
            approval or endorsement by us of that third party, third party website or of any product or service
            provided by a third
            party.
          </p>
        </li>

        <li>
          <ol class="extranum">
            <li>
              MEDICAL DISCLAIMER. THRIVEPASS IS NOT A MEDICAL REFERRAL SERVICE AND DOES NOT RECOMMEND OR
              ENDORSE ANY PARTICULAR
              MEDICAL OR WELLNESS TREATMENT OF ANY KIND OR ANY PRACTITIONER. THRIVEPASS DOES NOT OFFER ADVICE
              REGARDING THE
              SUITABILITY OR APPROPRIATENESS OF ANY MEDICAL OR WELLNESS TREATMENT FOR ANY MEDICAL CONDITION.
              NEITHER THE SITE, THE
              SERVICES, NOR ANY OF THE INFORMATION CONTAINED HEREIN IS INTENDED TO BE, NOR SHOULD IT BE
              CONSTRUED AS A SUBSTITUTE FOR
              PROFESSIONAL HEALTH OR MEDICAL ADVICE. THRIVEPASS (INCLUDING ITS OFFICERS, DIRECTORS, EMPLOYEES
              AGENTS, REPRESENTATIVES
              AND AFFILIATES) EXPRESSLY DISCLAIMS ALL RESPONSIBILITY AND LIABILITY ARISING FROM (A) YOUR USE
              OF OR RELIANCE ON THE
              SITE, THE SERVICES, OR THE INFORMATION CONTAINED HEREIN AS A HEALTH REFERENCE SOURCE, (B) ANY
              ADVICE, TREATMENT, OR
              SERVICES RENDERED BY ANY THIRD PARTY PROVIDER, PARTNER OR AFFILIATE FEATURED ON THIS WEB SITE,
              OR (C) ANY CLAIMS THAT
              MAY RESULT DIRECTLY OR INDIRECTLY FROM YOUR USE THEREOF. SHOULD YOU HAVE ANY MEDICAL OR HEALTH
              CARE RELATED QUESTIONS,
              OR IF YOU HAVE ANY REASON TO SUSPECT THAT YOU HAVE ANY CONDITION THAT MIGHT REQURIE MEDICAL
              ATTENTION, THRIVEPASS
              ADVISES YOU TO SEEK APPROPRIATE MEDICAL ATTENTION AND CARE PROMPTLY. ALWAYS CONSULT WITH YOUR
              PHYSICIAN OR QUALIFIED
              HEALTH CARE PROFESSIONAL BEFORE EMBARKING ON ANY NEW TREATMENT, DIET, FITNESS OR WELLNESS
              PROGRAM.
              <p>
                INFORMATION YOU PROVIDE IS NOT PROTECTED UNDER CONFIDENTIALITY LAWS THAT PROTECT
                PHYSICIAN-PATIENT COMMUNICATIONS, SO
                PLEASE CAREFULLY SELECT WHAT YOU CHOOSE TO DISCLOSE. WE ARE COMMITTED TO PROTECTING THE
                PERSONAL INFORMATION WE RECEIVE
                FROM YOU AND USE REASONABLE SECURITY MEASURES TO MAINTAIN THE SECURITY OF SUCH INFORMATION,
                HOWEVER, WE CANNOT FULLY
                ELIMINATE SECURITY RISKS ASSOCIATED WITH THE TRANSMISSION OF PERSONAL INFORMATION THROUGH
                ONLINE TRANSACTIONS, AND YOU
                DO SO AT YOUR OWN RISK.
              </p>
              <p>
                THRIVEPASS ASSUMES NO LIABILITY IN REGARDS TO USERS OF LISTED VENDOR FACILITIES. WE ALSO
                ASSUME NO LIABILITY IN THE CASE
                OF ANY HARM THAT MAY RESULT DIRECTLY OR INDIRECTLY FROM THE USE OF ANY VENDOR FEATURED ON
                THE PLATFORM.
              </p>
            </li>
            <li>
              Services Disclaimer. To the extent the Platform contains retirement, pre-tax financial, decision
              support, or financial
              wellness information, please know that THRIVEPASS is designed to provide you with a general
              education on retirement
              plans, pre-tax plans, decision support and personal financial wellness. THRIVEPASS is not
              intended to be legal, tax,
              investment or accounting advice. Any calculator or financial modeling tools provided within
              these modules are designed
              to be informational and educational only; any results presented are hypothetical. THRIVEPASS
              does not warrant the
              accuracy of this information or its applicability to your particular circumstances – rather,
              THRIVEPASS specifically
              disclaims any such warranties and any other liability arising out of the use of the Platform.
            </li>
          </ol>
        </li>
        <li>
          DISCLAIMER OF WARRANTY. ACCESS TO THE SITE, THE SERVICES, THE MATERIALS, AND TECHNOLOGY AVAILABLE ON OR
          THROUGH THE SITE
          OR DOWNLOADED THEREFROM IS PROVIDED “AS IS” AND “AS AVAILABLE” “WITH ALL FAULTS” AND WITHOUT ANY
          WARRANTY OF ANY KIND,
          EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THRIVEPASS AND ITS
          LICENSORS DISCLAIM
          ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED
          WARRANTIES OF TITLE,
          MERCHANTIBILITY, AND FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT. WITHOUT LIMITING THE
          FOREGOING,
          NEITHER THRIVEPASS NOR ITS LICENSORS WARRANT THAT ACCESS TO THE SITE AND/OR THE SERVICES WILL BE
          UNINTERRUPTED OR
          ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED; NOR DOES THRIVEPASS AND ITS LICENSORS MAKE ANY
          REPRESENTATIONS
          ABOUT THE ACCURACY, RELIABILITY, CURRENCY, QUALITY, COMPLETENESS, USEFULNESS, PERFORMANCE, SECURITY,
          LEGALITY OR
          SUITABILITY OF THE SITE, THE SERVICES OR ANY OF THE MATERIALS AND TECHNOLOGY AVAILABLE ON OR THROUGH THE
          SITE OR THE
          SERVICES OR DOWNLOADED THEREFROM. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE AND THE SERVICES AND
          YOUR RELIANCE UPON
          THE MATERIALS AND TECHNOLOGY AVAILABLE ON OR THROUGH THE SITE, THE SERVICES OR DOWNLOADED THEREFROM IS
          AT YOUR SOLE
          RISK. FURTHER, THRIVEPASS AND ITS LICENSORS MAKE NO REPRESENTATION OR WARRANTIES THAT THE SITE OR THE
          SERVICES OR THE
          MATERIALS AND TECHNOLOGY AVAILABLE ON OR THROUGH THE SITE OR THE SERVICES OR DOWNLOADED THEREFROM IS
          APPROPRIATE OR
          AVAILABLE FOR USE IN ALL GEOGRAPHIC LOCATIONS. IF YOU USE THE SITE OR THE SERVICES OR ANY MATERIALS OR
          TECHNOLOGY
          AVAILABLE ON OR THROUGH THE SITE OR THE SERVICES FROM OUTSIDE THE UNITED STATES OF AMERICA, YOU ARE
          SOLELY RESPONSIBLE
          FOR COMPLIANCE WITH ALL APPLICABLE LAWS, INCLUDING WITHOUT LIMITATION EXPORT AND IMPORT REGULATIONS OF
          OTHER
          COUNTRIES.NEITHER THRIVEPASS NOR ANY THIRD PARTY PROVIDERS, PARTNERS OR AFFILIATES WARRANT THAT THIS
          SITE, ITS SERVERS
          OR ANY E-MAIL SENT FROM THRIVEPASS OR ANY THIRD PARTY PROVIDERS, PARTNERS OR AFFILIATES ARE FREE OF
          VIRUSES OR OTHER
          HARMFUL COMPONENTS.
        </li>
        <li>
          LIMITATION OF LIABILITY. WE ARE NOT LIABLE TO YOU OR ANY OTHER PERSON FOR ANY PUNITIVE, EXEMPLARY,
          CONSEQUENTIAL,
          INCIDENTAL, INDIRECT OR SPECIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INJURY, LOST
          PROFITS, BUSINESS
          INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR COMPUTER OR OTHERWISE) ARISING FROM OR IN
          CONNECTION WITH YOUR USE
          OF THE SITE, THE SERVICES OR ITS MATERIALS OR TECHNOLOGY, WHETHER UNDER A THEORY OF BREACH OF CONTRACT,
          NEGLIGENCE,
          STRICT LIABILITY, MALPRACTICE OR OTHERWISE, EVEN IF THRIVEPASS OR THEY HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH
          DAMAGES. YOU HEREBY RELEASE THRIVEPASS AND HOLD THRIVEPASS AND ITS PARENTS, SUBSIDIARIES, AFFILIATES, OR
          LICENSORS, AND
          THEIR OFFICERS, DIRECTORS, TRUSTEES, AFFILIATES, SUBCONTRACTORS, AGENTS AND EMPLOYEES, HARMLESS FROM ANY
          AND ALL CLAIMS,
          DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE (INCLUDING, WITHOUT LIMITATION, ACTUAL, SPECIAL,
          INCIDENTAL AND
          CONSEQUENTIAL), KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF
          OR IN ANY WAY
          CONNECTED WITH YOUR USE OF THE SITE, THE SERVICES OR ITS MATERIALS OR TECHNOLOGY. YOU WAIVE THE
          PROVISIONS OF ANY STATE
          OR LOCAL LAW LIMITING OR PROHIBITING A GENERAL RELEASE.
        </li>

        <li>
          EXCLUSIVE REMEDY. IN THE EVENT OF ANY PROBLEM WITH THE SITE, THE SERVICES OR ANY OF ITS MATERIALS OR
          TECHNOLOGY, YOU
          AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY IS TO CEASE USING THE SITE, THE SERVICES, THE MATERIALS AND/OR
          THE TECHNOLOGY.
          UNDER NO CIRCUMSTANCES SHALL THRIVEPASS, ITS AFFILIATES, OR LICENSORS BE LIABLE IN ANY WAY FOR YOUR USE
          OF SITE, THE
          SERVICES OR ANY OF ITS MATERIALS OR TECHNOLOGY, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS
          IN ANY MATERIALS,
          ANY INFRINGEMENT BY THE MATERIALS, AND/OR THE TECHNOLOGY OF THE INTELLECTUAL PROPERTY RIGHTS OR OTHER
          RIGHTS OF THIRD
          PARTIES, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE ,THE SERVICES
          OR ANY OF
          MATERIALS OR TECHNOLOGY AVAILABLE THEREON. Certain states and/or jurisdictions do not allow the
          exclusion of implied
          warranties or limitation of liability for incidental or consequential damages, so the exclusions set
          forth above may not
          apply to you.
        </li>
        <li>
          TERMINATION/EXCLUSION. We reserve the right, in our sole discretion, to revoke, terminate or suspend any
          privileges
          associated with accessing our Site for any reason or for no reason whatsoever, including improper use of
          this Site or
          failure to comply with these Terms of Use, and to take any other action we deem appropriate. You agree
          that THRIVEPASS
          shall not be liable to you or any third party for any termination of your access to the Site and/or to
          any Materials
          therein.
        </li>
        <li>
          INDEMNIFICATION. You agree to indemnify, hold harmless, and defend THRIVEPASS and its licensors,
          suppliers, officers,
          directors, employees, agents, affiliates, subsidiaries, successors and assigns (collectively
          "Indemnified Parties") from
          and against any and all liability, loss, claim, damages, expense, or cost (including but not limited to
          attorneys'
          fees), incurred by or made against the Indemnified Parties in connection with any claim arising from or
          related to your
          use of the Site, the Services or any of its Materials or technology, or any breach or violation of these
          Terms of Use by
          you. You agree to fully cooperate as reasonably required by an Indemnified Party(ies). Each Indemnified
          Party may, at
          its own expense, assume the exclusive defense and control of any matter for which it is indemnified
          hereunder. You shall
          not settle any matter without the consent of the applicable Indemnified Party.
        </li>
        <li>
          Governing Law; Dispute Resolution.
          You agree that all matters relating to your access to or use of the site, including all disputes, will
          be governed by
          the laws of the United States and by the laws of the State of Delaware without regard to its conflicts
          of laws
          provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in
          Denver County,
          Colorado, and waive any objection to such jurisdiction or venue. Any claim under these Terms of Use must
          be brought
          within one (1) year after the cause of action arises, or such claim or cause of action is barred. No
          recovery may be
          sought or received for damages other than out-of-pocket expenses, except that the prevailing party will
          be entitled to
          costs and attorneys’ fees. In the event of any controversy or dispute between THRIVEPASS and you arising
          out of or in
          connection with your use of the site, the parties shall attempt, promptly and in good faith, to resolve
          any such
          dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty
          (30) days), then
          either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved
          through mediation,
          then the parties shall be free to pursue any right or remedy available to them under applicable law.
        </li>
        <li>
          MISCELLANEOUS. You acknowledge that a violation or attempted violation of any of these Terms of Use will
          cause such
          damage to THRIVEPASS as will be irreparable, the exact amount of which would be impossible to ascertain
          and for which
          there will be no adequate remedy at law. Accordingly, you agree that THRIVEPASS shall be entitled as a
          matter of right
          to an injunction issued by any court of competent jurisdiction, restraining such violation or attempted
          violation of
          these terms and conditions by you, or your affiliates, partners, or agents, as well as to recover from
          you any and all
          costs and expenses sustained or incurred by THRIVEPASS in obtaining such an injunction, including,
          without limitation,
          reasonable attorney's fees. You agree that no bond or other security shall be required in connection
          with such
          injunction.
        </li>
        <ol>
          <li>
            In the event any of the terms or provisions of this Agreement are determined to be invalid, illegal
            or otherwise
            unenforceable, the same shall not affect the other terms or provisions hereof or the whole of this
            Agreement, but such
            term or provision shall be deemed modified to the extent necessary to render such term or provision
            enforceable. A
            waiver of or failure to insist on performance of any of the terms of the Agreement will not operate
            as a waiver of any
            subsequent default whether of the same or similar nature.
          </li>
          <li>
            A printed version of this Agreement and of any related Notice given in electronic form shall be
            admissible in judicial
            or administrative proceedings based upon or relating to this Agreement to the same extent and
            subject to the same
            conditions as other business documents and records originally generated and maintained in printed
            form.
          </li>
        </ol>
        <li>
          THRIVEPASS SERVICES
          <p>
            THRIVEPASS is a third party administrative service provider of pre-tax benefits for businesses to
            manage
            employee
            benefit plans through the Internet. The THRIVEPASS Site provides registered members with a
            cost-effective way to manage
            health and other benefit programs. As a third party administrator, THRIVEPASS will not and does not
            provide advice in
            regards to: finance, legal, tax, or medical benefit programs. Personal information regarding life
            events, financial or
            legal, or tax concerns is provided on our Site ONLY for informational purposes. Hereafter, you
            release
            THRIVEPASS from
            and waive any and all claims, demands, and causes of action of every kind and nature, known and
            unknown,
            which exist or
            hereafter arise against the Company related in any way to the choice of your benefit plan, or the
            actions of any third
            party provider under any plan associated with the benefit’s plan.
          </p>
        </li>
      </ol>
    </div>
  </form>
</div>-->




<div class="container-new">
  <div class="container-title">
    <h3 class="ttl">{{modalTitle}}</h3>
  </div>
  <div class="dialog-close-button">
    <button mat-dialog-close class="cut-button" matTooltip="Close">
      <mat-icon matTooltip="Close">close</mat-icon>
    </button>
  </div>
  <div class="main-content">
    <div class="start-text">
      <p>Last modified: June 11, 2020</p>
      <p class="bold copyright bold-X ">© COPYRIGHT <span class="year">{{currentYear}}</span> THRIVEPASS Online ALL RIGHTS RESERVED.</p>

      <p>
        YOUR ACCEPTANCE OF THE TERMS OF USE. Welcome to THRIVEPASS! The website thrivepass.com (the “Site”) is
        brought to you by
        ThrivePass, Inc. (“THRIVEPASS,” “we,” “us,” or “our”). This user agreement sets forth the “terms
        and
        conditions” which govern your use of the Site and of the services and features made available on or through
        the Site (as
        further described in Section 2.A below and including, but not limited to the “Registered User Services,” the
        “Services”).
      </p>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE (the “Terms of Use,” or the “Agreement”) CAREFULLY BEFORE USING
        THIS SITE
        AS THEY CONSTITUTE A LEGALLY BINDING CONTRACT BETWEEN YOU AND THRIVEPASS AND GOVERN YOUR ACCESS TO THE SITE.
        BY
        ACCESSING OR USING THIS SITE AND/OR THE SERVICES IN ANY MANNER, YOU INDICATE YOUR UNCONDITIONAL ACCEPTANCE
        OF (1) THESE
        TERMS OF USE, (2) OUR PRIVACY POLICY (“THE PRIVACY POLICY”); AND (3) ANY OTHER LEGAL NOTICES, CONDITIONS, OR
        GUIDELINES
        LOCATED ON THE SITE. IF YOU DO NOT AGREE TO THESE TERMS OF USE OR THE PRIVACY POLICY, PLEASE DO NOT USE THE
        SITE OR
        SERVICES AND EXIT NOW. WE RESERVE THE RIGHT, IN OUR SOLE DISCRETION, TO UPDATE OR REVISE THESE TERMS OF USE
        FROM TIME TO
        TIME. YOUR CONTINUED USE OF THE SITE AND/OR SERVICES NOW, OR FOLLOWING THE POSTING OR NOTICE OF ANY CHANGES
        TO THE TERMS
        OF USE OR PRIVACY POLICY WILL INDICATE YOUR ACCEPTANCE OF THOSE CHANGES.
      </p>
      <ol>
        <li>
          SITE USERS. Two types of users may access the Site and use the Services – "Visitors" and "Registered
          Users."
        </li>

        <li>
          Visitors: To become a visitor of the Site (“Visitor”), you must read, agree with, and consent to be
          bound by all the
          terms and conditions set forth in these Terms of Use and the Privacy Policy As a Visitor, You may
          access the Site, and
          read our general information. You may NOT access any password-restricted information and/or portions
          of the Site, or
          upload any information.
        </li>
        <li>
          Registered Users: To become a registered user of the Site (“Registered User”) and to access the
          password-restricted
          areas of the Site and the Registered User Services (as defined in Section 2.A below), You must read,
          agree with, and
          consent to be bound by all the terms and conditions set forth in these Terms of Use, and the Privacy
          Policy. You may
          become a Registered User of the Site, by accessing the unique link provided to you upon initiation,
          for the Site.
          Without limiting the foregoing, the password-restricted areas of the Site and the Registered User
          Services are not
          available to temporarily or indefinitely suspended Registered Users or to persons under 18 years of
          age.
          <br><br>You are responsible for maintaining the confidentiality and security of your account and
          password, and you are fully
          responsible for all activities that occur under your password or account, and for any other actions
          taken in connection
          with the account or password. You agree to (a) immediately notify THRIVEPASS of any known or
          suspected unauthorized
          use(s) of your password or account, or any known or suspected breach of security, including loss,
          theft, or unauthorized
          disclosure of your password or credit card information; and (b) ensure that you exit from your
          account at the end of
          each session. THRIVEPASS will not be liable for any injury, loss or damage of any kind arising from
          or relating to your
          failure to comply with (a) and (b) or for any acts or omissions by you or someone else using your
          account and/or
          password.
        </li>
      </ol>
      <li>
        USE OF CONTENT AND SERVICES AVAILABLE THROUGH THE SITE.
        <p>
          Content. The Site contains a wide variety
          of Content (defined below), whether (1) proprietary to THRIVEPASS, or (2)
          proprietary to third parties, or Content proprietary to our commercial partners. “Content” includes,
          but
          is not limited
          to text, software, scripts, data, graphics, photos, sounds, music, videos, audiovisual combinations,
          interactive
          features and other materials that you may view or access through the Site, and Registered User
          Content.
          Under no
          circumstances will THRIVEPASS be liable in any way for any Content, including any errors or
          omissions in
          any Content or
          any Content that is libelous or defamatory, or any loss or damage of any kind incurred as a result
          of
          your use of any
          Content. You agree that you must evaluate and bear all risks associated with the use of any Content,
          including any
          reliance on the Content, integrity, and accuracy of such Content.
        </p>
      </li>
      <ol>
        <li>
          Services. Through the Site, we offer (1) to Visitors and to Registered Users free of cost access to
          our
          description of
          the service and (2) to Registered Users only, access to an online system that allows users to
          purchase
          wellness products
          and other employee benefit administration products, both one-time and recurring.
        </li>
        <li>Restrictions on Your Use of Content. The following restrictions apply to your use of Content:</li>
        <ol>
          <li>
            The Content and the trademarks, services marks and logos on the Site are owned by or licensed to
            THRIVEPASS, subject to
            copyright and other intellectual property rights under the law.
          </li>
          <li>
            Content is provided to You AS IS. You may access Content for your information and use solely as
            intended through the
            provided functionality of the Site and as permitted under this Agreement. You shall not use
            Content in any manner that
            infringes our or any third party's intellectual property rights, or other proprietary right or
            rights of publicity in or
            to the Content. All rights not expressly granted herein by THRIVEPASS and/or its licensors to
            you are reserved by
            THRIVEPASS and/or its licensors.
          </li>
          <li>
            You agree not to circumvent, disable or otherwise interfere with security-related features of
            the Site and/or Services
            that prevent or restrict the use or copying of any Content and not to alter, remove, or falsify
            any attributions or
            other proprietary designations of origin or source of any Content appearing on the Site or
            contained in a file that is
            uploaded to the Site.
          </li>
        </ol>
        <li>
          Restrictions on Your Rights to Use the Site/Materials. You agree that you shall not (and you agree
          not to allow any
          third party to): (i) access or use the Site and/or the Materials (as defined in Section 3 below) in
          an unlawful way or
          for an unlawful or illegitimate purpose or in any manner that contravenes this Agreement and our
          Privacy Policy; (ii)
          attempt to disrupt the operation of the Site through use of methods such as viruses, Trojan horses,
          worms, time bombs,
          denial of service attacks, flooding or spamming; (iii) use the Site/Materials in any manner that
          could damage, disable
          or impair our services or networks; (iv) attempt, through any means, to gain unauthorized access to
          the Site, Services,
          to a Registered User’s account, and/or any computer systems or networks, through hacking, password
          mining or any other
          means; (v) impersonate any person or entity, or falsely state or otherwise misrepresent your
          affiliation with a person
          or entity; (vi) use any robot, spider, site search/retrieval application, or other device to access,
          retrieve, screen
          scrape, screen surf or employ any other data scraping method, or index any portion of the
          Site/Services/Materials for
          any purpose. Systematic retrieval of data or other content from the Site to create or compile,
          directly or indirectly, a
          collection, compilation, database or directory is prohibited; (vii) create a Registered User account
          by automated means
          or register as a Registered User under false or fraudulent pretenses; (viii) create or transmit
          unwanted electronic
          communications such as "spam" to Registered Users or otherwise interfere with Visitors and/or
          Registered Users’
          enjoyment of the Site; (ix) reformat or frame any portion of the web pages that are part of the
          Site; (x) use the Site/
          Materials to defame, abuse, harass, threaten or otherwise violate the rights of others; (xi) use the
          Site to violate the
          security of any computer network, crack passwords or security encryption codes, transfer or store
          illegal material
          including those that are deemed threatening or obscene; (xii) take any action that imposes, or may
          impose in our sole
          discretion, an unreasonable or disproportionately large load on our IT infrastructure or that may
          otherwise interfere
          with the functioning of the Site/Services; (xiii) access or otherwise use any aspect of the Site/
          Materials if you are
          not in compliance with the age requirements; (xiv) create liability for THRIVEPASS or cause
          THRIVEPASS to lose (in whole
          or in part) the services of its information technology partners or other suppliers; (xv) use the
          Site/Materials,
          intentionally or unintentionally, to violate any applicable local, state, national or international
          law; or (xvi)
          collect or store Personal Information (as defined in our Privacy Policy) about Users in connection
          with any of the
          prohibited activities described in this paragraph, (xvi) alter, or attempt to alter any register
          user’s account balance.
        </li>
      </ol>
      <li>
        PROPRIETARY RIGHTS RESTRICTIONS.
        <p>
          All material on the Site, including, but not limited to,
          Content, all informational text, design of and "look and feel,"
          layout, photographs, graphics, audio, video, messages, interactive and instant messaging, design and
          functions, files,
          documents, images, or other materials (collectively, the "Materials"), whether publicly posted or
          privately transmitted,
          as well as all derivative works thereof, are owned by THRIVEPASS or other parties that have licensed
          their material to
          THRIVEPASS, and are protected by copyright, trademark, and other intellectual property laws. All
          THRIVEPASS trademarks
          and service marks, logos, slogans and taglines are the property of THRIVEPASS. All other trademarks,
          service marks,
          logos, slogans and taglines are the property of their respective owners. Except as specifically
          permitted, nothing
          contained herein should be construed as granting any license or right to use any trademarks, service
          marks, logos,
          slogans or taglines displayed on the Site without the express written permission of THRIVEPASS, or
          such
          third-party that
          may own the trademark, service mark, logo, slogan or tagline.
        </p>
      </li>
      <ol>
        <li>
          Except as otherwise specifically permitted herein, the Materials on the Site may not be copied,
          reproduced, republished,
          uploaded, posted, transmitted, or distributed in any way, including by e-mail or other electronic
          means, without the
          prior consent of THRIVEPASS or such third-party that owns the Materials. Any modification of the
          Materials, use of the
          Materials on any web site or networked computer environment, or use of the Materials for any purpose
          other than as
          specifically permitted herein or in the applicable Registered User agreement without the prior
          consent of THRIVEPASS or
          its licensors, as applicable, is a violation of the copyright, trademark, and other proprietary
          rights in the Materials
          and is expressly prohibited.
        </li>
      </ol>
      <li>
        CHARGES FOR SERVICES.
        <p>
          You understand and agree that you are responsible for all charges relating
          to your use of THRIVEPASS Registered User
          Services that are not covered by your employer. You will be informed of the fees to be charged when
          you
          select a
          wellness product. Fees may vary from provider to provider. Providers and vendors of wellness
          products
          may increase their
          prices or pass through additional costs and you will be responsible for such costs and fees. You
          should
          monitor your
          account regularly to review and confirm any price increase or additional charges or fees. You will
          be
          responsible for
          all charges or fees related to any wellness product you select that are not covered by your
          employer. In
          the event you
          dispute any fee or charge, you must dispute such charge directly with the provider. You are free to
          cancel the services
          of any provider of wellness products through the THRIVEPASS site.
          You authorize THRIVEPASS to charge your credit card for any fees or charges that are not covered by
          your
          employer. You
          will be asked to supply credit card information, which will be verified prior to your selection of
          any
          wellness
          products. You will not be able to use the THRIVEPASS Registered User Services to purchase wellness
          products if the
          credit card information you provide is inaccurate or if your credit card is declined.
          By selecting and utilizing wellness products, you authorize us to charge your credit card for any
          charges that are not
          covered by your employer. In order to facilitate payment for your wellness products, we will share
          your
          credit card
          information and related personal information with the designated credit card payment processor. This
          information is
          shared solely for the purpose of collecting the fees.
        </p>
        <p>
          You agree to update or renew a credit card that is expired or rejected. THRIVEPASS reserves the
          right to cancel any
          wellness products and set off future employer contributions you receive from your employer to cover
          unpaid fees if you
          do not provide a valid credit card within a reasonable amount of time. THRIVEPASS may provide you
          with a
          “virtual”
          credit card which may be used solely for payment for wellness products selected on the THRIVEPASS
          site.
          In the event of
          your misuse of any virtual credit card, THRIVEPASS reserves the right to report such misuse to your
          employer and to
          terminate your further use of any virtual credit card.
          In the event you incur any overcharges or additional fees from a provider of wellness products, such
          as
          charges or fees
          caused by additional services consumed by you or penalties for missed classes, you hereby authorize
          THRIVEPASS to deduct
          these overcharges or additional fees directly from any balance remaining in your THRIVEPASS account,
          to
          set off such
          overcharges or fees from future employer contributions or to charge your personal credit card.
        </p>
        <p>
          In
          the event that you terminate any wellness products purchased through the THRIVEPASS site or are
          credited
          for any
          other charges and a provider reimburses you for any such charges, THRIVEPASS shall credit the
          balance of
          your THRIVEPASS
          account for any such reimbursement received from such provider. In the event that a reimbursement
          received by THRIVEPASS
          is linked directly to a charge to your personal credit card and you provide a written request to
          have
          such charge
          credited to your credit card, THRIVEPASS will credit such direct reimbursement to your credit card.
          You
          hereby agree and
          acknowledge that THRIVEPASS will not credit to your credit card any reimbursements or credits linked
          to
          your employer
          contributions and any such credits or reimbursements may only be utilized through the purchase of
          wellness products
          through the THRIVEPASS site. THRIVEPASS shall have no responsibility for, and shall not provide any
          reimbursements or
          credits for, any purchases made, or transactions concluded, outside of the THRIVEPASS site.
        </p>
        <p>
          If
          you separate employment from your employer for any reason, you hereby agree and consent to
          THRIVEPASS
          charging all
          fees or other charges for wellness products to your credit card.
        </p>
      </li>
      <li>
        <p>MODIFICATIONS TO THESE TERMS OF USE AND OUR SITE AND/OR SERVICES.</p>
        <p>
          You agree and understand that
          the terms of this Agreement, the Site, and/or the Services may be modified by us at any
          time in our sole discretion without prior notice, and such modifications will be effective
          immediately
          upon our posting
          of the new terms and/or upon implementation of the new changes to the Site and/or Services. You
          acknowledge that
          THRIVEPASS reserves the right to discontinue the Services, or any features, functionality or
          services
          provided as part
          of or in connection with the Site and/or the Services, and to remove Content that violates the
          requirements set forth in
          this Agreement, without prior notice. While the non-password restricted portions of the Site are
          currently free of
          charge, you acknowledge that THRIVEPASS reserves the right to charge for aspects or additions to the
          Site and/or
          Services in the future.
        </p>
        <p>
          You agree to review this Agreement periodically so that you are aware of
          the most current rights and obligations, which
          apply to your agreement with us. Your continued use of the Site and/or the Services after any
          modifications indicates
          your acceptance of the modified Agreement. The most current version of this Agreement can be
          accessed at
          any time by
          selecting the Terms of Use link on the bottom of any page on the Site. You understand that the
          Content
          is provided as a
          courtesy to our Registered Users and Visitors and that we may suspend or terminate all or a portion
          of
          the Site and/or
          Services at any time in our discretion without notice to you. Nothing in these Terms of Use shall be
          deemed to confer
          any third-party rights or benefits.
        </p>
      </li>
      <li>
        VISITOR/USER SUGGESTIONS.<p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information provided by
          you to THRIVEPASS (“Comments”) are not confidential and you hereby grant to THRIVEPASS a worldwide,
          perpetual,
          irrevocable, royalty-free license to reproduce, display, perform, distribute, publish, modify, edit
          or
          otherwise use
          such Comments as it deems appropriate, for any and all commercial or non-commercial purposes, in its
          sole discretion.
        </p>
      </li>
      <li>
        DIGITAL MILLENIUM COPYRIGHT ACT. <p>
          THRIVEPASS respects the intellectual property of others and
          expects users of our services to do the same. If you believe
          that your copyrighted work has been copied in a way that constitutes copyright infringement and is
          accessible on this
          site or through this service, you must provide the following information when providing notice of
          the
          claimed
          infringement to THRIVEPASS:
        </p>
      </li>
      <ul>
        <li>
          A physical or electronic signature of a person authorized to act on behalf of the copyright owner
          and identification of
          the copyrighted work that is infringed;
        </li>
        <li>
          Information reasonably sufficient to permit THRIVEPASS to contact you, such as an address, telephone
          number and/or
          electronic mail address;
        </li>
        <li>
          A statement that you have a good faith belief that the use of the material in the manner complained
          of is not authorized
          by the copyright owner, its agent or law;
        </li>
        <li>
          A statement that the information in the notification is accurate and under penalty of perjury, that
          the complaining
          party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        </li>
      </ul>
      <p>
        The above information must be submitted as a written notification to THRIVEPASS by writing us at
        <a href="mailto:corp@thrivepass.com">corp@thrivepass.com</a>,
        ATTENTION: LEGAL DEPARTMENT/DMCA COMPLAINT. This information should not be construed as legal advice.
        For further
        details on the information required for valid DMCA notifications, see 17 U.S.C. 512(c)(3).
      </p>
      <li>
        LINKS TO THIRD PARTY SITES AND SERVICES; NO IMPLIED ENDORSEMENT.<p>
          This Site contains links to other web
          sites owned by third parties, which may lead to some sites maintained by our
          advertisers. Please note that when you click on any of these links, you are entering another web
          site for which
          THRIVEPASS has no responsibility or control. You agree that THRIVEPASS shall not be responsible for
          any loss or damage
          of any sort incurred as a result of any such links or as the result of the presence of such links on
          our Site. In no
          event shall any reference to any third party, third party website or third party product or service
          be construed as an
          approval or endorsement by us of that third party, third party website or of any product or service
          provided by a third
          party.
        </p>
      </li>

      <li>
        <ol class="extranum">
          <li>
            MEDICAL DISCLAIMER. THRIVEPASS IS NOT A MEDICAL REFERRAL SERVICE AND DOES NOT RECOMMEND OR
            ENDORSE ANY PARTICULAR
            MEDICAL OR WELLNESS TREATMENT OF ANY KIND OR ANY PRACTITIONER. THRIVEPASS DOES NOT OFFER ADVICE
            REGARDING THE
            SUITABILITY OR APPROPRIATENESS OF ANY MEDICAL OR WELLNESS TREATMENT FOR ANY MEDICAL CONDITION.
            NEITHER THE SITE, THE
            SERVICES, NOR ANY OF THE INFORMATION CONTAINED HEREIN IS INTENDED TO BE, NOR SHOULD IT BE
            CONSTRUED AS A SUBSTITUTE FOR
            PROFESSIONAL HEALTH OR MEDICAL ADVICE. THRIVEPASS (INCLUDING ITS OFFICERS, DIRECTORS, EMPLOYEES
            AGENTS, REPRESENTATIVES
            AND AFFILIATES) EXPRESSLY DISCLAIMS ALL RESPONSIBILITY AND LIABILITY ARISING FROM (A) YOUR USE
            OF OR RELIANCE ON THE
            SITE, THE SERVICES, OR THE INFORMATION CONTAINED HEREIN AS A HEALTH REFERENCE SOURCE, (B) ANY
            ADVICE, TREATMENT, OR
            SERVICES RENDERED BY ANY THIRD PARTY PROVIDER, PARTNER OR AFFILIATE FEATURED ON THIS WEB SITE,
            OR (C) ANY CLAIMS THAT
            MAY RESULT DIRECTLY OR INDIRECTLY FROM YOUR USE THEREOF. SHOULD YOU HAVE ANY MEDICAL OR HEALTH
            CARE RELATED QUESTIONS,
            OR IF YOU HAVE ANY REASON TO SUSPECT THAT YOU HAVE ANY CONDITION THAT MIGHT REQURIE MEDICAL
            ATTENTION, THRIVEPASS
            ADVISES YOU TO SEEK APPROPRIATE MEDICAL ATTENTION AND CARE PROMPTLY. ALWAYS CONSULT WITH YOUR
            PHYSICIAN OR QUALIFIED
            HEALTH CARE PROFESSIONAL BEFORE EMBARKING ON ANY NEW TREATMENT, DIET, FITNESS OR WELLNESS
            PROGRAM.
            <p>
              INFORMATION YOU PROVIDE IS NOT PROTECTED UNDER CONFIDENTIALITY LAWS THAT PROTECT
              PHYSICIAN-PATIENT COMMUNICATIONS, SO
              PLEASE CAREFULLY SELECT WHAT YOU CHOOSE TO DISCLOSE. WE ARE COMMITTED TO PROTECTING THE
              PERSONAL INFORMATION WE RECEIVE
              FROM YOU AND USE REASONABLE SECURITY MEASURES TO MAINTAIN THE SECURITY OF SUCH INFORMATION,
              HOWEVER, WE CANNOT FULLY
              ELIMINATE SECURITY RISKS ASSOCIATED WITH THE TRANSMISSION OF PERSONAL INFORMATION THROUGH
              ONLINE TRANSACTIONS, AND YOU
              DO SO AT YOUR OWN RISK.
            </p>
            <p>
              THRIVEPASS ASSUMES NO LIABILITY IN REGARDS TO USERS OF LISTED VENDOR FACILITIES. WE ALSO
              ASSUME NO LIABILITY IN THE CASE
              OF ANY HARM THAT MAY RESULT DIRECTLY OR INDIRECTLY FROM THE USE OF ANY VENDOR FEATURED ON
              THE PLATFORM.
            </p>
          </li>
          <li>
            Services Disclaimer. To the extent the Platform contains retirement, pre-tax financial, decision
            support, or financial
            wellness information, please know that THRIVEPASS is designed to provide you with a general
            education on retirement
            plans, pre-tax plans, decision support and personal financial wellness. THRIVEPASS is not
            intended to be legal, tax,
            investment or accounting advice. Any calculator or financial modeling tools provided within
            these modules are designed
            to be informational and educational only; any results presented are hypothetical. THRIVEPASS
            does not warrant the
            accuracy of this information or its applicability to your particular circumstances – rather,
            THRIVEPASS specifically
            disclaims any such warranties and any other liability arising out of the use of the Platform.
          </li>
        </ol>
      </li>
      <li>
        DISCLAIMER OF WARRANTY. ACCESS TO THE SITE, THE SERVICES, THE MATERIALS, AND TECHNOLOGY AVAILABLE ON OR
        THROUGH THE SITE
        OR DOWNLOADED THEREFROM IS PROVIDED “AS IS” AND “AS AVAILABLE” “WITH ALL FAULTS” AND WITHOUT ANY
        WARRANTY OF ANY KIND,
        EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THRIVEPASS AND ITS
        LICENSORS DISCLAIM
        ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED
        WARRANTIES OF TITLE,
        MERCHANTIBILITY, AND FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT. WITHOUT LIMITING THE
        FOREGOING,
        NEITHER THRIVEPASS NOR ITS LICENSORS WARRANT THAT ACCESS TO THE SITE AND/OR THE SERVICES WILL BE
        UNINTERRUPTED OR
        ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED; NOR DOES THRIVEPASS AND ITS LICENSORS MAKE ANY
        REPRESENTATIONS
        ABOUT THE ACCURACY, RELIABILITY, CURRENCY, QUALITY, COMPLETENESS, USEFULNESS, PERFORMANCE, SECURITY,
        LEGALITY OR
        SUITABILITY OF THE SITE, THE SERVICES OR ANY OF THE MATERIALS AND TECHNOLOGY AVAILABLE ON OR THROUGH THE
        SITE OR THE
        SERVICES OR DOWNLOADED THEREFROM. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE AND THE SERVICES AND
        YOUR RELIANCE UPON
        THE MATERIALS AND TECHNOLOGY AVAILABLE ON OR THROUGH THE SITE, THE SERVICES OR DOWNLOADED THEREFROM IS
        AT YOUR SOLE
        RISK. FURTHER, THRIVEPASS AND ITS LICENSORS MAKE NO REPRESENTATION OR WARRANTIES THAT THE SITE OR THE
        SERVICES OR THE
        MATERIALS AND TECHNOLOGY AVAILABLE ON OR THROUGH THE SITE OR THE SERVICES OR DOWNLOADED THEREFROM IS
        APPROPRIATE OR
        AVAILABLE FOR USE IN ALL GEOGRAPHIC LOCATIONS. IF YOU USE THE SITE OR THE SERVICES OR ANY MATERIALS OR
        TECHNOLOGY
        AVAILABLE ON OR THROUGH THE SITE OR THE SERVICES FROM OUTSIDE THE UNITED STATES OF AMERICA, YOU ARE
        SOLELY RESPONSIBLE
        FOR COMPLIANCE WITH ALL APPLICABLE LAWS, INCLUDING WITHOUT LIMITATION EXPORT AND IMPORT REGULATIONS OF
        OTHER
        COUNTRIES.NEITHER THRIVEPASS NOR ANY THIRD PARTY PROVIDERS, PARTNERS OR AFFILIATES WARRANT THAT THIS
        SITE, ITS SERVERS
        OR ANY E-MAIL SENT FROM THRIVEPASS OR ANY THIRD PARTY PROVIDERS, PARTNERS OR AFFILIATES ARE FREE OF
        VIRUSES OR OTHER
        HARMFUL COMPONENTS.
      </li>
      <li>
        LIMITATION OF LIABILITY. WE ARE NOT LIABLE TO YOU OR ANY OTHER PERSON FOR ANY PUNITIVE, EXEMPLARY,
        CONSEQUENTIAL,
        INCIDENTAL, INDIRECT OR SPECIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INJURY, LOST
        PROFITS, BUSINESS
        INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR COMPUTER OR OTHERWISE) ARISING FROM OR IN
        CONNECTION WITH YOUR USE
        OF THE SITE, THE SERVICES OR ITS MATERIALS OR TECHNOLOGY, WHETHER UNDER A THEORY OF BREACH OF CONTRACT,
        NEGLIGENCE,
        STRICT LIABILITY, MALPRACTICE OR OTHERWISE, EVEN IF THRIVEPASS OR THEY HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH
        DAMAGES. YOU HEREBY RELEASE THRIVEPASS AND HOLD THRIVEPASS AND ITS PARENTS, SUBSIDIARIES, AFFILIATES, OR
        LICENSORS, AND
        THEIR OFFICERS, DIRECTORS, TRUSTEES, AFFILIATES, SUBCONTRACTORS, AGENTS AND EMPLOYEES, HARMLESS FROM ANY
        AND ALL CLAIMS,
        DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE (INCLUDING, WITHOUT LIMITATION, ACTUAL, SPECIAL,
        INCIDENTAL AND
        CONSEQUENTIAL), KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF
        OR IN ANY WAY
        CONNECTED WITH YOUR USE OF THE SITE, THE SERVICES OR ITS MATERIALS OR TECHNOLOGY. YOU WAIVE THE
        PROVISIONS OF ANY STATE
        OR LOCAL LAW LIMITING OR PROHIBITING A GENERAL RELEASE.
      </li>

      <li>
        EXCLUSIVE REMEDY. IN THE EVENT OF ANY PROBLEM WITH THE SITE, THE SERVICES OR ANY OF ITS MATERIALS OR
        TECHNOLOGY, YOU
        AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY IS TO CEASE USING THE SITE, THE SERVICES, THE MATERIALS AND/OR
        THE TECHNOLOGY.
        UNDER NO CIRCUMSTANCES SHALL THRIVEPASS, ITS AFFILIATES, OR LICENSORS BE LIABLE IN ANY WAY FOR YOUR USE
        OF SITE, THE
        SERVICES OR ANY OF ITS MATERIALS OR TECHNOLOGY, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS
        IN ANY MATERIALS,
        ANY INFRINGEMENT BY THE MATERIALS, AND/OR THE TECHNOLOGY OF THE INTELLECTUAL PROPERTY RIGHTS OR OTHER
        RIGHTS OF THIRD
        PARTIES, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE ,THE SERVICES
        OR ANY OF
        MATERIALS OR TECHNOLOGY AVAILABLE THEREON. Certain states and/or jurisdictions do not allow the
        exclusion of implied
        warranties or limitation of liability for incidental or consequential damages, so the exclusions set
        forth above may not
        apply to you.
      </li>
      <li>
        TERMINATION/EXCLUSION. We reserve the right, in our sole discretion, to revoke, terminate or suspend any
        privileges
        associated with accessing our Site for any reason or for no reason whatsoever, including improper use of
        this Site or
        failure to comply with these Terms of Use, and to take any other action we deem appropriate. You agree
        that THRIVEPASS
        shall not be liable to you or any third party for any termination of your access to the Site and/or to
        any Materials
        therein.
      </li>
      <li>
        INDEMNIFICATION. You agree to indemnify, hold harmless, and defend THRIVEPASS and its licensors,
        suppliers, officers,
        directors, employees, agents, affiliates, subsidiaries, successors and assigns (collectively
        "Indemnified Parties") from
        and against any and all liability, loss, claim, damages, expense, or cost (including but not limited to
        attorneys'
        fees), incurred by or made against the Indemnified Parties in connection with any claim arising from or
        related to your
        use of the Site, the Services or any of its Materials or technology, or any breach or violation of these
        Terms of Use by
        you. You agree to fully cooperate as reasonably required by an Indemnified Party(ies). Each Indemnified
        Party may, at
        its own expense, assume the exclusive defense and control of any matter for which it is indemnified
        hereunder. You shall
        not settle any matter without the consent of the applicable Indemnified Party.
      </li>
      <li>
        Governing Law; Dispute Resolution.
        You agree that all matters relating to your access to or use of the site, including all disputes, will
        be governed by
        the laws of the United States and by the laws of the State of Delaware without regard to its conflicts
        of laws
        provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in
        Denver County,
        Colorado, and waive any objection to such jurisdiction or venue. Any claim under these Terms of Use must
        be brought
        within one (1) year after the cause of action arises, or such claim or cause of action is barred. No
        recovery may be
        sought or received for damages other than out-of-pocket expenses, except that the prevailing party will
        be entitled to
        costs and attorneys’ fees. In the event of any controversy or dispute between THRIVEPASS and you arising
        out of or in
        connection with your use of the site, the parties shall attempt, promptly and in good faith, to resolve
        any such
        dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty
        (30) days), then
        either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved
        through mediation,
        then the parties shall be free to pursue any right or remedy available to them under applicable law.
      </li>
      <li>
        MISCELLANEOUS. You acknowledge that a violation or attempted violation of any of these Terms of Use will
        cause such
        damage to THRIVEPASS as will be irreparable, the exact amount of which would be impossible to ascertain
        and for which
        there will be no adequate remedy at law. Accordingly, you agree that THRIVEPASS shall be entitled as a
        matter of right
        to an injunction issued by any court of competent jurisdiction, restraining such violation or attempted
        violation of
        these terms and conditions by you, or your affiliates, partners, or agents, as well as to recover from
        you any and all
        costs and expenses sustained or incurred by THRIVEPASS in obtaining such an injunction, including,
        without limitation,
        reasonable attorney's fees. You agree that no bond or other security shall be required in connection
        with such
        injunction.
      </li>
      <ol>
        <li>
          In the event any of the terms or provisions of this Agreement are determined to be invalid, illegal
          or otherwise
          unenforceable, the same shall not affect the other terms or provisions hereof or the whole of this
          Agreement, but such
          term or provision shall be deemed modified to the extent necessary to render such term or provision
          enforceable. A
          waiver of or failure to insist on performance of any of the terms of the Agreement will not operate
          as a waiver of any
          subsequent default whether of the same or similar nature.
        </li>
        <li>
          A printed version of this Agreement and of any related Notice given in electronic form shall be
          admissible in judicial
          or administrative proceedings based upon or relating to this Agreement to the same extent and
          subject to the same
          conditions as other business documents and records originally generated and maintained in printed
          form.
        </li>
      </ol>
      <li>
        THRIVEPASS SERVICES
        <p>
          THRIVEPASS is a third party administrative service provider of pre-tax benefits for businesses to
          manage
          employee
          benefit plans through the Internet. The THRIVEPASS Site provides registered members with a
          cost-effective way to manage
          health and other benefit programs. As a third party administrator, THRIVEPASS will not and does not
          provide advice in
          regards to: finance, legal, tax, or medical benefit programs. Personal information regarding life
          events, financial or
          legal, or tax concerns is provided on our Site ONLY for informational purposes. Hereafter, you
          release
          THRIVEPASS from
          and waive any and all claims, demands, and causes of action of every kind and nature, known and
          unknown,
          which exist or
          hereafter arise against the Company related in any way to the choice of your benefit plan, or the
          actions of any third
          party provider under any plan associated with the benefit’s plan.
        </p>
      </li>


    </div>
  </div>

</div>
