import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public _snackRef: MatSnackBarRef<TopBannerComponent>) {
  }

  public performAction() {
    this._snackRef.dismissWithAction();
  }
  public dismiss() {
    this._snackRef.dismiss();
  }
}
