export const Role = {
  participant: 'participant',
  masterUser: 'master-user',
  clientAdmin: 'client-administrator'
};

export const ReportType = {
  allAnalytics: 'all-analytics'
};

export const ReportFormat = {
  csv: 'csv',
  pdf: 'pdf'
};

export const AnalyticsChartView = {
  number: 'number',
  percentage: 'percentage'
};

export const AnalyticsChartType = {
  doughnut: 'doughnut',
  bar: 'bar'
};
