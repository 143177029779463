import { Injectable } from '@angular/core';
import { Subject, filter } from 'rxjs';
import mixpanel from 'mixpanel-browser';
import { Role } from '../../shared/common/constants';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedInFlag = new Subject<boolean>();
  public isLoginRouteFlag$ = this.isLoggedInFlag.asObservable();
  msalInitialized = false;
  constructor(private msalService: MsalService, private msalBroadcastService: MsalBroadcastService, private loaderService: LoaderService) {

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.msalInitialized = true;
      })
  }

  async loginRedirect() {
    this.loaderService.startProgressBar();
    this.msalService.loginRedirect();
  }

  public logout() {
    this.loaderService.startProgressBar();
    localStorage.clear();
    this.msalService.logout();
    mixpanel.reset();
  }

  public getUserNameObject(): any {
    const user = this.msalService.instance.getActiveAccount();
    if (user) {
      let nameObject = {
        'firstName': user.name?.split(" ")[0],
        'fullName': user.name,        
        email: user.username,
        objectId: user.localAccountId,
        initials: '',
        'username': '',
      };
      let firstNameChar = user.name?.split(" ")[0][0] || "";
      let lastNameChar = user.name?.split(" ")[1][0] || "";

      nameObject.initials = firstNameChar + lastNameChar;
      return nameObject;
    }

    return [];  
  }

  public showTokenResponse(url: any) {
    if (!url && url.indexOf('#') === -1)
      return null;

    const hash = url.split('#')[1];
    const response = hash.split('&').reduce((result: any, item: any) => {
      const parts = item.split('=');
      result[parts[0]] = parts[1];
      return result;
    }, {});

    return response;
  }

  public isLoggedIn() {
    return this.msalService.instance.getActiveAccount() != null;
  }

  public parseJwt(token: any): string {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  public setIsLoggedInFlag(state: boolean) {
    this.isLoggedInFlag.next(state);
  }

  public doesUserHaveAdminRole(userRoles: string[]): boolean {
    return (userRoles.indexOf(Role.clientAdmin) > -1 || userRoles.indexOf(Role.clientAdmin) > -1);
  }

  async getToken() {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      if (this.msalInitialized) {
        const accessTokenRequest = {
          scopes: ["openid", "profile"],
          account: account,
        };

        const tokenResponse = await this.msalService.instance
          .acquireTokenSilent(accessTokenRequest)
          .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return this.msalService.instance.acquireTokenRedirect(accessTokenRequest)
            } else {
              return null;
            }
          });
        return tokenResponse;
      } else {
        setTimeout(() => {
          return this.getToken();
        }, 200)
      }
    }
  }
}
