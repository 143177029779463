import { Component, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { UserIdleService } from '../../services/user-idle.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  public isLoggedIn = false;
  public currentYear: any;
  public isCookiesAccepted: boolean = true;

  @Output()
  public isNavDrawerOpen = new EventEmitter<boolean>();

  public constructor(public authService: AuthService, public router: Router, private commonService: CommonService, private cdr: ChangeDetectorRef, private userService: UserService, public snackBar: MatSnackBar, private userIdleService: UserIdleService, public dialog: MatDialog, private apiService: ApiService) {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.currentYear = new Date().getFullYear();
    this.checkCookiesAccepted();
  }

  public checkCookiesAccepted() {
    this.apiService.get('user/cookies/info').subscribe((res: any) => {
      if (res) {
        this.isCookiesAccepted = res.returnedObject.isCookiesAccepted;
      }
    })
  }

  public cookiesAfterLogin(data: any) {
    this.isCookiesAccepted = data;
  }

  public openTermDialog() {
    const termDialog = this.dialog.open(TermsOfUseComponent, this.commonService.getMatDialogOptions(null, 'mat-dialog-container__full-screen'));
  }

  public openPrivacyDialog() {
    const privacyDialog = this.dialog.open(PrivacyPolicyComponent, this.commonService.getMatDialogOptions(null, 'mat-dialog-container__full-screen'));
  }
}
