import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { contentHeaders } from '../../shared/common/headers';
import { ApiResponse } from '../models/api.model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }

  public get(url: string, params?: any): Observable<ApiResponse<any>> {
    let hasParams = false;
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        httpParams = httpParams.set(key, params[key]);
        hasParams = true;
      });
    }

    return this.http
      .get(this.configurationService.apiBaseUrl + 'api/v1/' + url, {
        params: hasParams ? httpParams : undefined,
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public post(url: string, body?: any, params?: any): Observable<ApiResponse<any>> {
    let hasParams = false;
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        httpParams = httpParams.set(key, params[key]);
        hasParams = true;
      });
    }

    return this.http
      .post(this.configurationService.apiBaseUrl + 'api/v1/' + url, body, {
        params: hasParams ? httpParams : undefined,
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
