import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class EnrollmentGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private userService: UserService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.checkIfUserIsValid().pipe(
      map((response:any) => {
        if (response && response.success) {
          if (response.returnedObject === false) {
            this.router.navigateByUrl('/user/dashboard');
            return false;
          }
        }

        return true;
      }));
  }
}
