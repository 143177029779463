import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../services/common.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private commonService: CommonService) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: () => null,
        error: (err: HttpErrorResponse) => {
          let errorMessage = "There was some error processing your request. Please contact support.";
          if (err.status === 401 || err.status === 403)
            this.authService.logout();

          if (err.status === 429) {
            if (err.error && err.error && err.error.message)
              errorMessage = err.error.message;

            else
              errorMessage = 'Whoa! Calm down, cowboy! Quota exceeded.';
          }

          if (err.status === 400) {
            if (err.error && err.error.message)
              errorMessage = err.error.message;

            else if (err.error && err.error.error && err.error.error.userMessage)
              errorMessage = err.error.error.userMessage;
          }

          if (!err || (!err.status && !err.error))
            return;

          this.commonService.showTopBanner(errorMessage, '', 60000000, null);
          return throwError(errorMessage);
        },
      })
    );
  }

}
