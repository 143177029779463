import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'terms-of-use',
  templateUrl: 'terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})

export class TermsOfUseComponent {
  public modalTitle: string;
  public currentYear: string;

  constructor(public dialogRef: MatDialogRef<TermsOfUseComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalTitle = 'Terms of Use';
    this.currentYear = new Date().getFullYear().toString();
  }
}
