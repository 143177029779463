import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { UserIdleService } from './services/user-idle.service';
import { RedirectGuard } from './guards/redirect.guard';
import { MaterialModule } from './material.module';
import { AppState } from './services/app.service';
import { CommonService } from './services/common.service';
import { AnalyticsService } from './services/analytics.service';
import { AdminService } from './services/admin.service';
import { NgProgress } from '@ngx-progressbar/core';
import { UserService } from './services/user.service';
import { LoaderComponent } from './components/loader/loader.component';
import { TrustHtmlPipe } from '../shared/common/pipes/trust-html.pipe';
import { PagerService } from './services/pager.service';
import { AdminGuard } from './guards/admin.guard';
import { EnrollmentGuard } from './guards/enrollment.guard';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { SnackbarService } from './services/snackbar.service';
import { AcceptCookiesComponent } from './components/accept-cookies/accept-cookies.component';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [
    LoaderComponent,
    TopBannerComponent,
    FooterComponent,
    AcceptCookiesComponent,
    ToastComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    MaterialModule,
    LoaderComponent,
    TopBannerComponent,
    FooterComponent,
    AcceptCookiesComponent,
    ToastComponent
  ],
  providers: [
    //services
    AuthService,
    UserIdleService,
    CommonService,
    AnalyticsService,
    AdminService,
    NgProgress,
    UserService,
    PagerService,
    SnackbarService,
    //Guards
    RedirectGuard,
    AppState,
    AdminGuard,
    EnrollmentGuard,
    //interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    //pipes

    TrustHtmlPipe
  ],
})
export class CoreModule { }
