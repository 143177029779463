import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) { }

  openCOnfirmationSnackBar(message: string, action1: string, action2: string) {
    const snackBarRef = this.snackbar.open(message, action1, {
      duration: 5000,
      panelClass: ['snackbar']
    });

    snackBarRef.onAction().subscribe(() => {
      // Action 1 (Yes) logic
      snackBarRef.dismissWithAction();
    });

    snackBarRef.afterDismissed().subscribe((dismissedAction:any) => {
      if (dismissedAction === 'action') {
        // Action 2 (No) logic
      }
    });

   // snackBarRef.addAction(action2);
  }
}
