import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { IpService } from '../../services/ip.service';


@Component({
  selector: 'accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.component.scss']
})

export class AcceptCookiesComponent {
  @Output() isCookiesAcceptedBeforeLogin: EventEmitter<boolean> = new EventEmitter();
  @Output() isCookiesAcceptedAfterLogin: EventEmitter<boolean> = new EventEmitter();

  public latitude: any;
  public longitude: any;
  public isLoggedIn: boolean = false;
  constructor(private apiService: ApiService, private authService: AuthService, private ipService: IpService) {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  public acceptCookies() {
    this.isCookiesAcceptedAfterLogin.emit(true);
    var browserInfo = this.getBrowserName();
    this.getLocation();
    var data = {
      browserInfo: browserInfo,
      latitude: this.latitude,
      longitude: this.longitude,
      ipAddress: this.ipService.getIPAddress()
    }
    this.apiService.post('user/cookies/add', data).subscribe((response: any) => {
      if (response) {

      }
    })
  }

  public getBrowserName() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isEdge = userAgent.indexOf("edg") > -1 || userAgent.indexOf("edge") > -1;
    const isOpera = userAgent.indexOf("opr") > -1 || userAgent.indexOf("opera") > -1;
    const isChrome = userAgent.indexOf("chrome") > -1 && !isOpera && !isEdge;
    const isIE = userAgent.indexOf("trident") > -1 || userAgent.indexOf("msie") > -1;
    const isFirefox = userAgent.indexOf("firefox") > -1;
    const isSafari = userAgent.indexOf("safari") > -1 && !isChrome && !isOpera && !isEdge;

    if (isEdge) {
      return 'Edge';
    } else if (isOpera) {
      return 'Opera';
    } else if (isChrome) {
      return 'Chrome';
    } else if (isIE) {
      return 'Internet Explorer';
    } else if (isFirefox) {
      return 'Firefox';
    } else if (isSafari) {
      return 'Safari';
    } else {
      return 'Other';
    }
  }

  public getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        },
        (error) => {
          console.error('Error getting location', error);
        }
      );
    }
  }

  public openPrivacyDialog() {
    window.open("https://www.thrivepass.com/privacy");
  }

  public acceptCookiesWithoutLoggedIn() {
    if (this.authService.isLoggedIn()) {
      this.getLocation();
      this.acceptCookies();
    }
    else {
      this.isCookiesAcceptedBeforeLogin.emit(false);
    }
  }
}
