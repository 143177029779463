import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor() { }
  public getIPAddress() {
    var request = new XMLHttpRequest();
    request.open('GET', "https://api.ipify.org/?format=json", false);
    request.send(null);
    const response = JSON.parse(request.responseText);
    return response.ip;
  }
}
