import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable()
export class AdminGuard implements CanActivateChild {
  constructor(private authService: AuthService, private userService: UserService) { }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return this.userService.checkIfUserHasAdminRole().pipe(
        map(response => {
          if (response && response.success && response.returnedObject)
            return true;
          else {
            this.authService.logout();
            return false;
          }
        }));
    }

    this.authService.logout();
    return false;
  }
}
