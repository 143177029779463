import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private configData: any;
  
  constructor(private httpClient: HttpClient) {

  }

  public loadConfig(): Promise<any> {
    const promise = this.httpClient.get('/config')
      .toPromise()
      .then((response: any) => {
        this.configData = response.value;
        return this.configData;
      });
    return promise;
  }

  get apiBaseUrl(): any {
    return this.configData.apiBaseUri;
  }

  get clientBaseUrl(): any {
    return this.configData.clientBaseUri;
  }

  get tPNexusClientBaseUrl(): any {
    return this.configData.tpNexusConfig.clientUrl;
  }

  get msalConfig(): any {
    return this.configData.msalConfig;
  }

  get config(): any {
    return this.configData;
  }
}
