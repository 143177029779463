import { Component, Inject } from "@angular/core";
import { inject } from '@angular/core/testing';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})

export class PrivacyPolicyComponent {
  public modalTitle: string;
  public currentYear: string;

  constructor() {
    this.modalTitle = 'Privacy Policy';
    this.currentYear = new Date().getFullYear().toString();
  }
}
