<!--Logged in-->
<div class="dashboard-home-footer-container">
  <div class="footer-left">
    © {{currentYear}} | ALL RIGHTS RESERVED
  </div>
  <div class="footer-right">
    <a class=footer-anchor href="mailto:support-de@thrivepass.com">SUPPORT</a>
  </div>
</div>

<div class="cookies-dialog cookies-dialog-footer" >
  <accept-cookies class="acceptanceClass" *ngIf="!isCookiesAccepted" (isCookiesAcceptedAfterLogin)="cookiesAfterLogin($event)"></accept-cookies>
</div>

<!--Logged out-->

