import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import mixpanel from 'mixpanel-browser';
import { AuthService } from './auth.service';

@Injectable()
export class AnalyticsService {
  private analyticsToken!: string;

  constructor(private configuration: ConfigurationService, private authService: AuthService) {
    this.analyticsToken = this.configuration.config.analyticsConfig.token;
  }

  public readonly analyticsEvent = {
    verifyYourIdentity: 'Verify Your Identity',
    login: 'Login',
    accountCreated: 'Account Created',
    startSetUpAccount: 'Start Account Set-upt',
    informationConfirmed: 'Information Confirmed',
    informationNotConfirmed: 'information Not Confirmed',
    enrollmentStarted: 'Enrollment Started',
    cobraPlanDetailsPageView: 'COBRA Plan Details Page View',
    enrollmentComplete: 'Enrollment Complete',
    enrollmentInComplete: 'Enrollment InComplete',// take me back on enrollment confirmations
    viewMoreAfforableOptions: 'View More Afforable Options',
    thatsOk: 'Thats Ok',
    takeMeBack: 'Take Me Back',
    planSelect: 'Plan Select',
    browseOtherPlansPageView: 'Browse Other Plans Page View',
    filter: 'Filter',
    sort: 'Sort',
    viewPlanDetails: 'View Plan Details ',
    startQuestionaire: 'Start Questionaire',
    answeredQuestion: 'Answered Question ',
    questionnaireCompleted: 'Questionnaire Completed',
  }

  public initialize() {
    mixpanel.init(this.analyticsToken, { debug: true, cross_subdomain_cookie: false });

    var user = this.authService.getUserNameObject();

    if (Object.keys(user).length > 0) {
      mixpanel.identify(user.objectId);

      this.updateUserProfile({
        '$distinct_id': user.objectId,
        '$name': user.fullName,
        '$email': user.email,
        '$phone': null,
        'Username': user.username
      });
    }
  }

  public trackEvent(event: string, data: any = {}) {
    mixpanel.track(event, data);
  }

  public updateUserProfile(data: any) {
    mixpanel.people.set(data);
  }

  public trackEventListData(event: string, data: any = []) {
    mixpanel.track(event, data);
  }
}
