import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { contentHeaders } from '../../shared/common/headers';

@Injectable()
export class AdminService {
  private serviceUrl: string;

  constructor(private configuration: ConfigurationService, private http: HttpClient) {
    this.serviceUrl = this.configuration.apiBaseUrl;
  }

  public getSettingsConfigurationData(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/admin/settings/configuration-data/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error:any) => {
          return throwError(error);
        }));
  }

  public downloadReport(reportRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/report/download', reportRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error:any) => {
          return throwError(error);
        }));
  }

  public getRegistrationStatisticsAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/registration-statistics-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error:any) => {
          return throwError(error);
        }));
  }

  public getQuestionnaireUtilizationAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/questionnaire-utilization-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error:any) => {
          return throwError(error);
        }));
  }

  public getUserEnrollmentsAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/user-enrollment-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error:any) => {
          return throwError(error);
        }));
  }

  public getRegistrationFailuresAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/registration-failures-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error:any) => {
          return throwError(error);
        }));
  }

  public getCobraElectionAnalysisAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/cobra-election-analysis-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public getEmployerSubsidizedCobraElectionsAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/employer-subsidized-cobra-elections-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public getYtdMarketplaceEnrollmentAnalyticsData(analyticsRequest: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/analytics/ytd-marketplace-enrollment-data/get', analyticsRequest, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public getClients(pageNumber: any, pageSize: any, search:any = ''): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/admin/clients/get', {
        headers: contentHeaders,
        params: new HttpParams()
          .set('pageNumber', pageNumber)
          .set('pageSize', pageSize)
          .set('search', search)
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public getPlans(clientId: string, pageNumber: any, pageSize: any, search: any = ''): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/admin/client/plans/get', {
        headers: contentHeaders,
        params: new HttpParams()
          .set('b2BAccountId', clientId)
          .set('pageNumber', pageNumber)
          .set('pageSize', pageSize)
          .set('search', search)
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public addUpdateB2BAccountPlan(planInformation: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/client/plan/add-update', planInformation, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public toggleB2BAccount(clientId: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/client/' + clientId + '/status/toggle', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public addUpdateB2BAccount(b2BAccountInfo: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/client/add-update', b2BAccountInfo, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public toggleB2BAccountPlan(planId: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/client/plan/' + planId + '/status/toggle', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  public getFailedCredentials(pageNumber: any, pageSize: any, search: any = ''): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/admin/failed-credential-logs/get', {
        headers: contentHeaders,
        params: new HttpParams()
          .set('pageNumber', pageNumber)
          .set('pageSize', pageSize)
          .set('search', search)
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public reSharedCredential(data: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/admin/credentials/re-share', data, {
        headers: contentHeaders,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public isNexusB2BAccountAlreadyExists(nexusB2BAccountId: any, clientId: any): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/admin/client/check-Nexus-b2BAccount', {
        headers: contentHeaders,
        params: new HttpParams()
          .set('nexusB2BAccountId', nexusB2BAccountId)
          .set('clientId', clientId)
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }
}
