import { Directive, HostListener, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
/*import { setTimeout } from 'timers';*/

@Directive({
  selector: '[numberFormatter]'
})

export class NumberFormatterDirective implements OnInit {
  private el: HTMLInputElement;
  private static decimalSeparator = '.';
  private static thousandsSeparator = ',';
  private static padding = '000000';

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Delete'];
  private specialCharacters = [
    { value: '$', affixType: 'prefix' },
    { value: '%', affixType: 'suffix' },
    { value: '×', affixType: 'suffix' }
  ];

  private validValue!: string;
  private allowZero: boolean = false;
  private autoFillZero: boolean = true;
  private specialCharacter: string = '';
  public specialCharacterToSupport: any = null;

  constructor(private elementRef: ElementRef, private control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
   // setTimeout(() => {
    //  this.allowZero = (this.el.attributes['allow_zero'] ? (this.el.attributes['allow_zero'].value == 'true') : false);
    //  this.autoFillZero = (this.el.attributes['auto_fill_zero'] ? (this.el.attributes['auto_fill_zero'].value == 'true') : true);
    //  this.specialCharacter = (this.el.attributes['special_character'] ? (this.el.attributes['special_character'].value) : '');
    //  if (this.specialCharacter) {
    //    this.specialCharacterToSupport = this.specialCharacters.filter((item) => {
    //      return (item.value === this.specialCharacter);
    //    })[0];
    //  }

    //  this.el.value = NumberFormatterDirective.transform(this.el.value, this.autoFillZero, this.allowZero, this.specialCharacterToSupport);
    //  this.control.control.setValue(this.el.value);
    //  this.validValue = this.el.value;
    //}, 0);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: KeyboardEvent) {
    this.checkForUpdatedSpecialCharacter();
    this.el.value = NumberFormatterDirective.parseBeginningZeroes(this.el.value, this.allowZero, this.specialCharacterToSupport);
    if (this.specialCharacterToSupport) {
      this.el.value = (this.specialCharacterToSupport.affixType === 'prefix' ? (this.specialCharacterToSupport.value + this.el.value) : (this.el.value + this.specialCharacterToSupport.value));
      setTimeout(() => {
        if (this.specialCharacterToSupport.affixType === 'suffix') {
         // this.el.selectionStart = (this.el.selectionStart - 1);
          this.el.selectionEnd = (this.el.selectionStart);
        }
      }, 0);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: KeyboardEvent) {
    this.checkForMinAndMaxLimit(event, true);
    this.el.value = NumberFormatterDirective.transform(this.el.value, this.autoFillZero, this.allowZero, this.specialCharacterToSupport);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.validValue = (this.el.value ? this.el.value : '0');
    let regex = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    // Allow all the special keys defined above
    if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey)
      return;

    const current: string = this.validValue;
    this.checkForUpdatedSpecialCharacter();
    const next: string = NumberFormatterDirective.parse(current, this.allowZero, this.specialCharacterToSupport);
    if (next && !String(next).match(regex))
      event.preventDefault();
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.checkForMinAndMaxLimit(event);
  }

  public static transform(value: number | string, autoFillZero: boolean = true, allowZero: boolean = false, specialCharacterToSupport: any = null): string {
    if (specialCharacterToSupport)
      value = (value || '').toString().replace(specialCharacterToSupport.value, '');

    let [integer, fraction = ''] = (value || '').toString().split(this.decimalSeparator);
    let fractionSize = value.toString().indexOf('.') !== -1 ? 2 : 0;
    fraction = fractionSize > 0 ? (this.decimalSeparator + (fraction + this.padding).substring(0, fractionSize)) : '';

    if (!allowZero || (allowZero && integer !== '0'))
      integer = integer.replace(/^0+/, ''); //Replaces all the '0' in the beginning.

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);
    if (integer === '' && fraction === '' && autoFillZero)
      integer = '0';

    if (specialCharacterToSupport && (integer !== '' || fraction !== ''))
      return (specialCharacterToSupport.affixType === 'prefix' ? (specialCharacterToSupport.value + integer + fraction) : (integer + fraction + specialCharacterToSupport.value));

    return integer + fraction;
  }

  public static parse(value: string, allowZero: boolean = false, specialCharacterToSupport: any = null): string {
    if (specialCharacterToSupport)
      value = value.replace(specialCharacterToSupport.value, '');

    value = (value || '').toString();
    let [integer, fraction = ''] = (value || '').split(this.decimalSeparator);
    let fractionSize = value.indexOf('.') !== -1 ? 2 : 0;
    integer = integer.replace(new RegExp(this.thousandsSeparator, 'g'), '');
    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0 ? this.decimalSeparator + (fraction + this.padding).substring(0, fractionSize) : '';

    if (integer === '0' && fraction === '' && !allowZero)
      integer = '';

    return integer + fraction;
  }

  public static parseBeginningZeroes(value: string, allowZero: boolean = false, specialCharacterToSupport: any = null): string {
    if (specialCharacterToSupport)
      value = value.replace(specialCharacterToSupport.value, '');

    let [integer, fraction = ''] = (value || '').split(this.decimalSeparator);
    let fractionSize = value.toString().indexOf('.') !== -1 ? 2 : 0;
    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0 ? this.decimalSeparator + (fraction + this.padding).substring(0, fractionSize) : '';

    if (integer === '0' && fraction === '' && !allowZero)
      integer = '';

    return (integer + fraction);
  }

  public checkForMinAndMaxLimit(event: KeyboardEvent, checkForMinLimit: boolean = false) {
    let current: string = this.validValue;
    let next: string = this.el.value;

    this.checkForUpdatedSpecialCharacter();
    if (this.specialCharacterToSupport) {
      current = current.replace(this.specialCharacterToSupport.value, '');
      next = next.replace(this.specialCharacterToSupport.value, '');
    }

    let currentCopy = current;
    let nextCopy = next;
    current = NumberFormatterDirective.parse(current, this.allowZero, this.specialCharacterToSupport);
    next = NumberFormatterDirective.parse(next, this.allowZero, this.specialCharacterToSupport);

    if (this.checkIfStringValueIsNotValidFloatNumber(next))
      next = '';

    let intPart: string ;
    let decimalPart: string;
    let isMaxLimitCrossed: boolean = false;
    let isMinLimitNotReached: boolean = false;

    if (this.el.max.indexOf(',') > -1)
      this.el.max = this.el.max.replace(/,/g, '');

    if (this.el.min.indexOf(',') > -1)
      this.el.min = this.el.min.replace(/,/g, '');

    let maxLimit = parseFloat(this.el.max);
    let minLimit = parseFloat(this.el.min);
    let value = parseFloat(next);
    if (next && (!String(next).match(this.regex) || (!isNaN(maxLimit) && value > maxLimit) || (checkForMinLimit && !isNaN(minLimit) && value < minLimit))) {
      //If current value is also incorrect
      if ((current && (!String(current).match(this.regex) || (!isNaN(maxLimit) && parseFloat(current) > maxLimit) || (checkForMinLimit && !isNaN(minLimit) && parseFloat(current) < minLimit)))) {
        if ((!isNaN(maxLimit) && parseFloat(current) > maxLimit)) {
          let maxLimitIntPart = this.el.max.substring(0, (this.el.max.indexOf('.') !== -1 ? this.el.max.indexOf('.') : this.el.max.length));
          if (current.length > maxLimitIntPart.length)
            current = current.substr(0, maxLimitIntPart.length) + (current.indexOf('.') !== -1 ? (current.substring(current.indexOf('.'), current.length)) : '');

          if (parseFloat(current) > maxLimit) {
            current = this.el.max;
            isMaxLimitCrossed = true;
          }

        } else {
          let minLimitIntPart = this.el.min.substring(0, (this.el.min.indexOf('.') !== -1 ? this.el.min.indexOf('.') : this.el.min.length));
          if (current.length > minLimitIntPart.length)
            current = current.substr(0, minLimitIntPart.length) + (current.indexOf('.') !== -1 ? (current.substring(current.indexOf('.'), current.length)) : '');
          if (parseFloat(current) < minLimit) {
            current = '';
            isMinLimitNotReached = true;
          }
        }
      } else if (checkForMinLimit && !current) {
        current = '';
        isMinLimitNotReached = true;
      }

      if (!isMaxLimitCrossed && (!isMinLimitNotReached || !checkForMinLimit) && currentCopy.indexOf('.') !== -1) {
        intPart = ((current.indexOf('.') !== -1) ? current.substring(0, current.indexOf('.')) : current);
        decimalPart = currentCopy.substring(currentCopy.indexOf('.'), currentCopy.length);
        if (decimalPart.length > 3)
          decimalPart = decimalPart.substr(0, 3);
      } else {
        intPart = current;
        decimalPart = '';
      }

      current = NumberFormatterDirective.parseBeginningZeroes(intPart + decimalPart, this.allowZero, this.specialCharacterToSupport);
      current = NumberFormatterDirective.transform(current, this.autoFillZero, this.allowZero, this.specialCharacterToSupport);
      this.el.value = current;
      this.control.control?.setValue(current);
      //this.el.selectionStart = (this.el.selectionStart - 1);
      this.el.selectionEnd = (this.el.selectionStart);
    } else {
      if (nextCopy.indexOf('.') !== -1) {
        intPart = ((next.indexOf('.') !== -1) ? next.substring(0, next.indexOf('.')) : next);
        decimalPart = nextCopy.substring(nextCopy.indexOf('.'), nextCopy.length);
        if (decimalPart.length > 3)
          decimalPart = decimalPart.substr(0, 3);

      } else {
        intPart = next;
        decimalPart = '';
      }

      if (!this.allowZero || (this.allowZero && intPart !== '0'))
        intPart = intPart.replace(/^0+/, ''); //Replaces all the '0' in the beginning.

      intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      next = intPart + decimalPart;
      if (this.specialCharacterToSupport && (intPart !== '' || decimalPart !== ''))
        next = (this.specialCharacterToSupport.affixType === 'prefix' ? (this.specialCharacterToSupport.value + intPart + decimalPart) : (intPart + decimalPart + this.specialCharacterToSupport.value));

      if (this.checkIfStringValueIsNotValidFloatNumber(next))
        next = '';

      this.el.value = next;
      this.control.control?.setValue(next);
      if (this.specialCharacterToSupport && this.specialCharacterToSupport.affixType === 'suffix' && this.el.selectionStart === (next.length)) {
        this.el.selectionStart = (this.el.selectionStart - 1);
        this.el.selectionEnd = (this.el.selectionStart);
      }
    }
  }

  public checkForUpdatedSpecialCharacter() {
    //let updatedSpecialCharacter = (this.el.attributes['special_character'] ? (this.el.attributes['special_character'].value) : '');
    //if (this.specialCharacter !== updatedSpecialCharacter) {
    //  this.specialCharacter = updatedSpecialCharacter;
    //  this.specialCharacterToSupport = this.specialCharacters.filter((item) => {
    //    return (item.value === this.specialCharacter);
    //  })[0];
    //}
  }

  public checkIfStringValueIsNotValidFloatNumber(value: string) {
    let isDecimalNumber = parseFloat(value);
    return isNaN(isDecimalNumber);
  }
}
