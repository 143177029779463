<div class="container" *ngIf="data">
  <p class="ebony message mb-0 mt-0" *ngIf="data.message && data.message !== ''"  [ngClass]="{'banner_white': data.fontWhite}">{{data.message}}</p>
  <div class="button-container">
    <div class='submit-button' *ngIf="data && data.actionButtonText && data.actionButtonText !== ''">
      <a mat-button class="mat-button__skeleton mat-button__disable-ripple" (click)="performAction()">
        <div class="overline sapphire">{{data.actionButtonText}}</div>
      </a>
    </div>
    <div class="close">
      <mat-icon mat-button class="cursor-pointer" matSuffix matTooltip="Dismiss" (click)="dismiss()" [ngClass]="{'banner_white': data.fontWhite}">close</mat-icon>

    </div>
  </div>
</div>
