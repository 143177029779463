import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, Subject } from 'rxjs';
import { AnalyticsService } from './modules/core/services/analytics.service';
import { AuthService } from './modules/core/services/auth.service';
import { LoaderService } from './modules/core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  isIframe = false;
  loginDisplay = false;
  public isCookiesAccepted: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  isProgressRunning: Subject<boolean> = this.loaderService.isProgressBarRunning;
  isLoaderRunning: Subject<boolean> = this.loaderService.isLoaderRunning;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: AuthService, private loaderService: LoaderService, private msalBroadcastService: MsalBroadcastService, private msalService: MsalService, private analyticsService: AnalyticsService) {
    this.analyticsService.initialize();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit(): void {

    if (!this.authService.isLoggedIn()) {
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
          console.log(result);
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);

        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None)
        )
        .subscribe(() => {
          this.setLoginDisplay();
        })
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }
}
