import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private msalService: MsalService, private commonService: CommonService, private authService: AuthService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = localStorage.getItem('token');
    if (accessToken && this.isTokenValid(accessToken)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } else {
      const accessToken = await this.authService.getToken();
      const token = accessToken ? accessToken?.idToken : localStorage.getItem('token');
      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    }
    return await lastValueFrom(next.handle(req));
  }

  public isTokenValid(token: any) {
    return token ? moment().isBefore(moment.unix(this.parseJwt(token).exp)) : false;
  }

  public parseJwt(token: any): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
