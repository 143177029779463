import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { contentHeaders } from '../../shared/common/headers';

@Injectable()
export class UserService {
  private serviceUrl!: string;

  constructor(private configuration: ConfigurationService, private http: HttpClient) {
    this.serviceUrl = this.configuration.apiBaseUrl;
  }

  public validateUser(userLoginRequestDTO: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/validate', userLoginRequestDTO, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getUserBasicInfo(model: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/basicInfo', model, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateUserBasicInfo(model: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/basicInfo/update', model, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public loginInfoHandling(): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/utility/login/info/handling', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  /*New service come from decision services */

  public getTermsAndConditions(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/terms-and-conditions/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateTermsAndConditions(): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/terms-and-conditions/accept', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  /**/
  public getUserDemographicInformation(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/demographic-info/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateUserDemographicInformation(data: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/demographic-info/update', data, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getDashboardInformation(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/dashboard-info/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getPlanInformation(planId: string, checkboxChecked: boolean): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/plan/' + planId + '/details/get/' + checkboxChecked + '', {
        headers: contentHeaders,
        params: new HttpParams()
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getDependents(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/dependents/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateUserDependents(data: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/dependents/update', data, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getQbCobraPlanInformation(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/cobra-plan/details/get', {
        headers: contentHeaders,
        params: new HttpParams()
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getCoverageTier(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/get/coverage/tier', {
        headers: contentHeaders,
        params: new HttpParams()
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getZipCodeCountyNameInformation(zipCode: string): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/county/' + zipCode + '/get/codes', {
        headers: contentHeaders,
        params: new HttpParams()
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getUserElectionCobraSubsidyInfo(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/election/cobra/subsidy/data/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateUserElectionInformation(data: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/election/update', data, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getUtilizationOption(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/utilization/option/list/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public checkIfUserIsValid(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/requested-url/authorization/verify', {
        headers: contentHeaders,
        params: new HttpParams()
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getRecommendedPlans(filters: any, zipcode: string = '', countyCode: string = ''): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/recommended/plans/get', filters, {
        headers: contentHeaders,
        params: new HttpParams()
          .set('zipCode', zipcode)
          .set('countyCode', countyCode)
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateUserHouseHoldIncome(houseHoldIncome: any): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/household/income/update', {
        headers: contentHeaders,
        params: new HttpParams()
          .set('houseHoldIncome', houseHoldIncome)
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public followupLogging(uniqueCode: string): Observable<any> { 
    return this.http
      .post(this.serviceUrl + 'api/v1/utility/followup/logging', { uniqueCode: uniqueCode }, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }


  public getLoggedInUserRoles(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/user-roles/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public recordUserClickOnEnrollButton(planType: string): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/enroll-button/' + planType + '/click/record', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public checkIfUserHasAdminRole(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/is-admin/check', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getZipCodeCountyNameAndStateInformation(zipCode: string): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/' + zipCode + '/county/codes/state/get', {
        headers: contentHeaders,
        params: new HttpParams()
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public updateSupplementalPlanTypeInNexus(hasUserOptedForMedical: any): Observable<any> {
    return this.http
      .post(this.serviceUrl + 'api/v1/user/supplementalPlanType/update/' + hasUserOptedForMedical, {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

  public getUserPlanAvailabilityAndPlanEnrollmentStatus(): Observable<any> {
    return this.http
      .get(this.serviceUrl + 'api/v1/user/planAvailability-PlanEnrollment-Status-info/get', {
        headers: contentHeaders
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }));
  }

}
