import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class RedirectGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      if (state.url == '/login' || state.url.split('?')[0] == '/registration' || state.url == '/') {
        this.router.navigateByUrl('/user/dashboard');
      }
      return true;
    }
    else if (state.url == '/' && !this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    return true;
  }
}
